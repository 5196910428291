import axios from 'axios'
import { handlePageRefresh, isIosDevice } from './commonFunction'
import CommonRouter from './commonRoute'

const createGuest = require('cross-domain-storage/guest')
const { apiEndpoint, isLive } = require('./commonApi')
const service = axios.create({
  headers: {

  }
})
const handleSuccess = (response) => {
  return response
}

const handleError = (error) => {
  return Promise.reject(error)
}
service.interceptors.response.use(handleSuccess, handleError)

/**
 * IW0079
 * When someone changes in local storage detail at that time that person account will logout for security purpose
 */
const logOutOnRefreshExp = () => {
  if (!isIosDevice() && isLive) {
    window.removeEventListener("beforeunload", handlePageRefresh)
    const accountLocalStorage = createGuest(`${process.env.LOGIN_DOMAIN}/log-in?is_logout&product=app`)
    return new Promise(async (resolve) => {
      accountLocalStorage.remove('is_log', function (err, value) {
        if (!value) {
          localStorage.removeItem('access_token')
          localStorage.removeItem('_ett')
          localStorage.removeItem('userData')
          document.cookie = `ent_dt =;expires=${new Date()}`
          localStorage.removeItem('refresh_tokens')
          localStorage.removeItem('user_ip')
          localStorage.removeItem('check_log')
        }
        window.location.href = CommonRouter.redirect_to_account
      })
      accountLocalStorage.remove('logged_app', function () { })
      accountLocalStorage.remove('common_data', function () { })
      resolve()
    })
  } else {
    window.removeEventListener("beforeunload", handlePageRefresh)
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_tokens')
    localStorage.removeItem('userData')
    window.location.href = CommonRouter.log_in
  }
}

const onCheckLogin = async () => {
  return new Promise(async (resolve) => {
    if (isLive) {
      const get_is_login = await createGuest(`${process.env.LOGIN_DOMAIN}/log-in?is_check_storage`)
      get_is_login.get('common_data', (error, value) => {
        if (value) {
          get_is_login.close()
          resolve(true)
        } else {
          logOutOnRefreshExp()
          resolve(false)
        }
      })
    }
  })
}

export const ApiCall = async (method, path, payload, header, timeout = 30000, isMain = false, responseType = 'json', controller = '') => {
  if (header && !header['access-token']) {
    logOutOnRefreshExp()
  } else {
    try {
      if (!isIosDevice()) {
        onCheckLogin()
      }
      const responce = await service.request({
        method,
        url: isMain ? path : `${apiEndpoint}${path}`,
        responseType,
        data: payload,
        timeout,
        headers: header,
        signal: controller.signal
      })
      return responce
    } catch (error) {
      if (error.message === 'Network Error') {
        console.log(`${error}, Server is not responding, please try again after some time`)
      }
      if (error.response?.data?.statusCode === 401 && header && !header['access-token']) {
        if (error.response.data.refresh_expire) {
          logOutOnRefreshExp()
        } else {
          return error.response
        }
      } else {
        return error.response
      }
    }
  }
}


/**
 * IW0079
 * here flag is true when api call occur and user is not login
 */
export const GetApiCall = async (method, path, header, flag = false, isMain = false, responseType = 'json', timeout = 30000) => {
  if (!header['access-token'] && !flag) {
    logOutOnRefreshExp()
  } else {
    try {
      if (!isIosDevice()) {
        onCheckLogin()
      }
      const responce = await service.request({
        method,
        url: isMain ? path : `${apiEndpoint}${path}`,
        responseType,
        headers: header,
        timeout
      })
      return responce
    } catch (error) {
      if (error.message === 'Network Error') {
        console.log(`${error}, Server is not responding, please try again after some time`)
      }
      if (error.response?.data?.statusCode === 401) {
        if (error.response.data.refresh_expire) {
          logOutOnRefreshExp()
        } else {
          return error.response
        }
      } else {
        return error.response
      }
    }
  }
}
