// ** Logo
import React from 'react'
const SpinnerComponent = () => {
  return (
    <div className='fallback-spinner app-loader  w-100'>
      <img src={"https://cdn.themunim.com/app/assets/images/M.gif"} />
    </div>
  )
}

export default SpinnerComponent