import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    channel_data: []
}

export const channelSlice = createSlice({
    name: 'channel',
    initialState,
    reducers: {
        setChannelData: (state, action) => {
            state.channel_data = action.payload
        }
    }
})

export const { setChannelData } = channelSlice.actions
export default channelSlice.reducer