// You can customize the template with the help of this file

//Template config options
const themeConfig = {
  app: {
    appName: '',
    appLogoImage: require('@src/assets/images/logo/menulogo.svg').default
  },
  layout: {
    routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: 'vertical', // vertical, horizontal
    menu: {
      isHidden: false,
      isCollapsed: false
    }
  }
}

export default themeConfig
